.App-Header {
  width: 100%;
  background-color: #fff;
  color: #fff;
  display: flex;
  height: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and ( min-width: 550px ) {
  .App-Header{
    height: 410px;
  }
}

.App-Header img{
  height: 300px;
}

.App-My-Des{
  font-size: 14px;
  /* color: #ffffff; */
}

.App-Content{
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 550px;
  font-size: 14px;
}

.App-Content li{
  margin-bottom: 5px;
}

.App-Content a{
  color: #0084FF;
  text-decoration: none;
}

.App-Header-Content{
  margin: 0 auto;
  max-width: 550px;
  font-size: 12px;
}

.App-Image{
  max-width: 350px;
}

.App-Svg{
  margin-right: 5px;
  height: 15px;
}