.header-container{
  width: 100%;
  height: 52px;
}

.header-content{
  height: 52px;
  margin: 0 auto;
  max-width: 550px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.header-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.header-title a{
  text-decoration: none;
  color: #000;
  font-size: 15px;
  font-weight: bold;
}

.header-title img {
  border-radius: 20px;
  width: 40px;
}

.header-nav-link{
  flex: 1;
  text-align: right;
}

.header-nav-link a{
  margin-right: 10px;
  text-decoration: none;
  color: rgba(0,0,0,.5);
  font-size: 12px;
}

.header-nav-link a:hover{
  color: rgba(0,0,0,.7);
}