.App-Header {
  width: 100%;
  background-color: #282c34;
  height: 350px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App-My-Des{
  font-size: 14px;
  /* color: #ffffff; */
}

.App-Content{
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 580px;
  font-size: 12px;
}

.App-Content a{
  color: #0084FF;
}

.App-Content a:hover{
  text-decoration: underline;
}